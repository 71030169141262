<template>
    <!-- match div -->
    <div class=" feeds-matches" >
        <div class="feed-schedule">
            <p v-if="$i18n.locale == 'en'" class="league-schedule">{{ game.competition }}<span class="ghgh"> - {{ game.status  }} {{ game.time }}'</span></p>
            <p v-if="$i18n.locale == 'fr'" class="league-schedule">{{ game.competitionFr }}<span  class="ghgh"> - {{ game.status  }} {{ game.time }}'</span></p>
            <router-link 
                :to="'/'+game.sportId + '/single-live-match/' + game.id" tag="p"  
                class="text-right more-markets" v-if="game.markets.length != 0"
                :class="{ 'text-orange':game.selected_odd_id == '','text-green':game.selected_odd_id != '' }"
            >
                +{{ game.size }} {{ $t('feeds.markets')}}
            </router-link>
        </div>
        <div class="d-grid two-column_">
          <div class="teams" @click="goToMoremarkets()">      
              <p>{{ game.home }}</p>
              <p>{{ game.away }}</p>
          </div>
          <div class="hidden-xs">
            <p class="status">{{ game.status }}'</p>
            <p class="status">{{ game.time }}</p>
          </div>
          <div>
            <template v-if="game.statistic && 'score' in game.statistic && game.statistic.score.ft.split(' ').length > 1">
              <p class="text-orange">{{ game.statistic.score.ft.split(' ')[0] }}</p>
              <p class="text-orange">{{ game.statistic.score.ft.split(' ')[1] }}</p>
            </template>
            <template v-if="game.statistic && 'score' in game.statistic && game.statistic.score.ft.split('-').length > 1">
              <p class="text-orange">{{ game.statistic.score.ft.split('-')[0] }}</p>
              <p class="text-orange">{{ game.statistic.score.ft.split('-')[1] }}</p>
            </template>
          </div>
          <div class="d-grid" :class="columnClass">
              <template v-if="Array.isArray(markets) || (markets && markets.odds.length != marketCount.selections.length)">
                <no_odd v-for="(_,i) in marketCount.selections.length" :key="`sss${i}`"/>
              </template>
              
              <template v-if="markets && markets.odds != undefined && markets.odds.length == marketCount.selections.length">
                <div v-frag v-for="(select,i) in marketCount.selections" :key="`mar${i}`">
                  <odd  :select="select" :game="game" :market="markets"/>
                </div>
              </template>
          </div>
        </div>
    </div>
    <!-- end -->
  </template>
  <script>
  import odd from './button.vue';
  import no_odd from '../feeds/no-odds-button.vue';
  import { getCountryISO2 } from "@/utils/utility";
  export default {
    props:['game','id','market'],
    components:{
      odd,
      no_odd
    },
    computed:{
        markets() {
          return this.game.markets.find( x => x.key == this.id  ) || [];
        },
        marketCount() {
          let count = this.market.markets.find( x => x.key == this.id ) || 0;
          if(count) {
            return count;
          } else {
            return [];
          }
        },
        columnClass() {
          let count = this.market.markets.find( x => x.key == this.id );
  
          if (count && count.selections.length > 2) {
              return "three-column";
          } else if(count && count.selections.length == 2) {
              return "two-column";
          } else {
              return "one"
          }
        }
    },
    methods: { 
      iso: function (code) {
        const res = getCountryISO2(code,'sidebar')
        if(res) {
            return res
        } else {
            return {
                "code": "FM",
                "code3": "DEF",
                "fr_name": code,
                "name": code,
                "number": "248"
            }
        }
      },
      goToMoremarkets: function() {
        this.$router.push('/'+ this.game.sportId + '/single-live-match/' + this.game.id)
      }
    },
    watch: {
      locale: function (newLocale) {
        this.locale = newLocale
      }
    }
  }
  </script>
  <style scoped>
    .feeds-matches {
      cursor: pointer;
    }
    .d-grid {
      display: grid;
      gap: 5px !important;
      align-content: center;
      justify-content: center;
      grid-template-rows: 35px;
      align-items: center;
    }
    .main-two-column {
      grid-template-columns: 2fr 1fr;
    }
    .two-column {
      grid-template-columns: 1fr 1fr;
    }
    .two-column_ {
      grid-template-columns: 1fr 40px 1fr;
    }
    .three-column {
      grid-template-columns: 1fr 1fr 1fr;
  
    }
    .feeds-matches {
      padding: 10px 0px;
      /* border: 1px solid #f7941e; */
      margin: 10px 0px;
    }
    .feeds-matches p {
      margin-bottom: 0px;
    }
    .feeds-matches .teams p {
      font-weight: 500;
      font-size: 13px;
    }
   
    .league-schedule {
      font-size: 12px;
      color: var(--a-grey);
    }
    .league-schedule span, p.status {
      color: var(--bg-orange);
      font-size: 12px;
    }
    .more-markets {
      /* color: var(--bg-orange); */
      /* margin-bottom: 8px; */
      cursor: pointer;
    }
    .feed-schedule {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 3px;
    }
    .feed-schedule p {
      margin-bottom: 0px !important;
    }
    @media screen and (max-width: 767px) {
      .two-column_ {
        grid-template-columns: 1fr 20px 1.2fr;
      }
      .ghgh {
        display: inline;
      }
    }

    @media screen and (min-width: 768px) {
      .two-column_ {
        grid-template-columns: 1fr 100px 40px 1fr;
      }
      .ghgh {
        display: none;
      }
    }
  </style>