<template>
    <div class="d-grid two-column_">
     <div class="">
       <span class="main-market" v-if="$i18n.locale == 'en'">{{ marketArr.en_name }}</span>
       <span class="main-market" v-if="$i18n.locale == 'fr'">{{ marketArr.fr_name }}</span>
     </div>
     <div></div>
     <div class="hidden-xs"></div>
     <div class="d-grid" :class="columnClass">
       <span class="text-center" style="text-transform: capitalize;" v-for="(mark,ind) in markets.selections" :key="ind">
         <template v-if="$i18n.locale == 'en'">
           {{ mark.name }}
         </template>
         <template v-if="$i18n.locale == 'fr'">
           {{ mark.shortName }}
         </template>
       </span>      
     </div>
   </div>
 </template>
 <script>
 export default {
    name:'market-header',
    data() {
        return {
    
        }
    },
    props: {
        marketArr: Object,
        index: String,
    },
    computed:{
        markets() {  
        if (this.marketArr.markets) {
            return this.marketArr.markets.find( (x) => x.key == this.index);
        } else {
            return { selections: [] }
        }
        
        },
        columnClass() {
        let count = (this.markets) ? this.marketArr.markets.find( (x) => x.key == this.index) : 1;
        if (count && count.selections.length > 2) {
            return "three-column";
        } else if(count && count.selections.length == 2) {
            return "two-column";
        } else {
            return "one"
        }
        }
    },
    methods: {
        showLeagueName: function (chk) {
            const arr = ['upcoming','featured','highlights','today']
            return arr.includes(chk)
        }
    },
 }
 </script>
 <style scoped>
   .d-grid {
     display: grid;
     gap: 10px;
     align-content: center;
     justify-content: center;
   }
   .main-two-column {
     grid-template-columns: 2fr 1fr;
   }
   .one-column {
     grid-template-columns: 1fr;
   }
   .two-column {
     grid-template-columns: 1fr 1fr;
   }
   .three-column {
     grid-template-columns: 1fr 1fr 1fr;
 
   }
   @media screen and (max-width: 767px) {
      .two-column_ {
        grid-template-columns: 1fr 20px 1.2fr;
      }
    }

    @media screen and (min-width: 768px) {
      .two-column_ {
        grid-template-columns: 1fr 100px 40px 1fr;
      }
    }
 </style>