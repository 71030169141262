<template>
    <div class="container-fluid feeds">
        <div class="col-sm-12 feeds">
            <div class="div-flex">
                <the-sidebar class="hidden-sm hidden-xs"/>
                <div class="feed">
                    <div class="contain-overflow">
                        <div class="quick-filters">
                            <ul class="list-inline" v-if="(market && marketId && market.markets != undefined)"> 
                                <li @click="toggleModal"  v-if="$i18n.locale == 'en'">{{ market.markets.find( x => x.key == marketId).name}} <i class="icofont-simple-down"></i></li>
                                <li @click="toggleModal"  v-if="$i18n.locale == 'fr'">{{ market.markets.find( x => x.key == marketId).fr_name}} <i class="icofont-simple-down"></i></li>
                            </ul>   
                        </div>
                        <div class="desktop-section-feeds">
                            <markets :marketArr="market" :index="marketId" />
                            <match v-for=" game in live" :key="game.id" :market="market" :game="game" :id="marketId" />
                        </div>
                    </div>
                </div>
                <the-betslip class="hidden-sm hidden-xs home-bet"/>
                <modal @close="toggleModal" @selectMarket="selectMarket" v-if="show && showType == 'markets'">
                    <span class="title">{{ $t('feeds.markets')}}</span>
                    <ul class="list-unstyled" v-if="(market && marketId && market.markets != undefined)">
                        <li v-for="(mar,i) in market.markets" :key="mar.key+i" @click="selectMarket(mar.key)">
                            <span v-if="$i18n.locale == 'en'" >{{ mar.name }} <i class="icofont-tick-mark text-orange" v-if="mar.key == marketId"></i></span>
                            <span v-if="$i18n.locale == 'fr'" @click="selectMarket(mar.id)">{{ ($i18n.locale == 'en') ? mar.name : mar.fr_name }} <i class="icofont-tick-mark text-orange" v-if="mar.key == marketId"></i></span>
                        </li>
                    </ul>
                </modal>
            </div>
        </div>
    </div>
</template>
<script>
import SocketioService from "@/services/socket";
import { mapGetters } from "vuex";
import match from '@/components/New/sections/live/match.vue'; 
import markets from '@/components/New/sections/live/markets.vue'; 
import modal from '../partials/modal.vue';
import BetslipService from "@/services/betslip";
import { getId } from "@/utils/utility";

export default {
    components:{
        match,
        markets,
        modal,
        
    },
    data() {
        return {
            publicPath: process.env.BASE_URL || '/',
            selectedMarket:0,
            show:false,
            showType:'',
            spid:1,
            room:'soccer',
            interval:null,
            polling: null,
        }
    },
    computed: {
        ...mapGetters(['live','live_markets']),
        obj() {
           return getId(this.$route.params.sport)
        },
        market() {
            return this.live_markets.find( x=> x.sportId == this.obj.sport);
        },
        marketId() {     
            return ( this.selectedMarket) ? this.selectedMarket : this.market.displayId;
        },
    },
    watch: {
        '$route': async function(newr,old) {   

            if(newr.params.sport != old.params.sport) {
                clearInterval(this.polling);
            }

            this.room = newr.params.sport;
            this.$store.dispatch("reset_live_game");       
            let obj = {
                sport: newr.params.sport,
                room: old.params.sport,
                id: this.obj.sport
            }
            await this.$store.dispatch("get_live_sport",this.obj.sport)

            this.polling = setInterval( async () => {
                await this.$store.dispatch("get_live_sport",this.obj.sport);
            }, 20000);
            
            SocketioService.changeChannel(obj);
            SocketioService.getLiveGames();

            
        },
        live: function(g) {
            if(g.length == 0) {
                clearInterval(this.interval);
            }

            if(g.length > 0) {
                this.slipsCheck(g)

                this.interval = setInterval(() => {
                    this.slipsCheck(g);
                }, 8000);
            }
        }
    },
    async created() {
        await this.$store.dispatch("get_live_sport",this.obj.sport);

        this.polling = setInterval( async () => {
            await this.$store.dispatch("get_live_sport",this.obj.sport);
        }, 20000);
       
        SocketioService.setupSocketConnection(this.obj.name,this.obj.sport);
        SocketioService.getLiveGames();

        this.subscription = BetslipService.getAddedBetslip().subscribe( () => {
          this.$store.dispatch("update_live_state");
        })

        this.mysubscription = BetslipService.getRemovedBetslip().subscribe( () => { 
            this.$store.dispatch("update_live_state");
        })
    },
    methods: {
        toggleModal: function() {
            this.show = !this.show;
            this.showType = 'markets';
        },
        selectMarket: function(id) {
            this.selectedMarket = id;
            this.show = false;
        },
        changeChannel: function(sport,id) {
            this.spid = id;
            clearInterval(this.interval);
            let obj = {
                sport: sport,
                room: this.room,
                id: id
            }
            SocketioService.changeChannel(obj);
            SocketioService.getLiveGames();
            this.room = sport;
        },
        slipsCheck: async function(data) {
            if(data) {
                let ids = data.map(el =>{ return el.id});
                await this.$store.dispatch("fetch_live_slips",ids);
            }
        }
    },
    mounted() {
        BetslipService.setSubjectBetslip();
    },
    beforeDestroy () {
        this.$store.dispatch("reset_live_game");
        clearInterval(this.interval);
        clearInterval(this.polling);
        this.subscription.unsubscribe()
        this.mysubscription.unsubscribe()
        SocketioService.leaveSRoom()
    }
}
</script>
<style scoped>
.um {
    padding: 90px 80px;
}
.um i {
    font-size: 50px;
    margin-right: 0px;
}
.quick-filters {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 0px;
}
.div-flex {
    display: flex;
  }
  .div-flex .feed {
    width: 100%;
  }
.quick-filters li,.quick-filters span {
    background-color: var(--bg-dark-medium);
    border-radius: 15px;
    padding: 4px 10px;
    margin: 0px 2px;
    font-size: 12px;
    
}
.quick-filters.btom li {
    /* font-weight: bold; */
    text-transform: capitalize;
}
.quick-filters.btom li i {
    cursor: pointer;
}
.quick-filters li i {
    margin-right: 0px;
}
.pd-top {
    padding-top: 30px;
}
.contain-overflow {
    padding-bottom: 50px;
}
</style>