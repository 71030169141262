<template>
    <div v-frag>
      <template v-if="market.id != 18">
        <template v-for="mar in market.odds">
          <span 
            v-if="select.id == mar.outcomeId && parseFloat(mar.odds) > 0 && mar.status == 1"
            :key="mar.id"
            class="odd"
            @click="addToBetslip(mar,game,market)"
            :class ="{ 'selected' : mar.key == game.selected_odd_id || sharedBet.find( x => x.oddId == mar.key ) }"
        >
          {{ parseFloat(mar.odds).toFixed(2) }} 
          <i v-if="mar.up" class="fa fa-caret-up text-green" aria-hidden="true"></i>
          <i v-if="mar.down" class="fa fa-caret-down text-red" aria-hidden="true"></i>
        </span> 
        <span class="odd" v-if="select.id == mar.outcomeId && parseFloat(mar.odds) == 0 && mar.status == 1"  :key="mar.id"><i class="icofont-lock"></i></span>
        <span class="odd" v-if="select.id == mar.outcomeId && mar.status != 1"  :key="mar.id"><i class="icofont-lock"></i></span>
        </template>
      </template>
      <template v-if="market.id == 18">
        <template v-for="mar in market.odds">
          <span 
            v-if="select.id == mar.outcomeId && parseFloat(mar.odds) > 0 && (mar.specifier == 'total=2.5' || mar.specifier == 'total=1.5')"
            :key="mar.id"
            class="odd"
            @click="addToBetslip(mar,game,market)"
            :class ="{ 'selected' : mar.key == game.selected_odd_id || sharedBet.find( x => x.oddId == mar.key ) }"
        >
          {{ parseFloat(mar.odds).toFixed(2) }} 
          <i v-if="mar.up" class="fa fa-caret-up text-green" aria-hidden="true"></i>
          <i v-if="mar.down" class="fa fa-caret-down text-red" aria-hidden="true"></i>
        </span> 
        <!-- <span class="odd" v-if="select.id == mar.outcomeId && parseFloat(mar.odds) == 0"  :key="mar.id"><i class="icofont-lock"></i></span> -->
        </template>
      </template>
    </div>
    
  </template>
  <script>
  import BetslipService from "@/services/betslip";
  import {mapGetters} from "vuex";
  export default {
    props:['select','game','market'],
    methods:{
      addToBetslip: function(odds, match, market) {  
  
        let obj= {
          id:match.id,
          home: match.home,
          away:match.away,
          sportId: match.sportId,
          scheduled: match.scheduled,
  
          market: {
              id:market.marketId,
              mid:market.id,
              mhandicap: market.handicap,
              mname: market.name,
              frname: market.frName,
              oddId: odds.key,
              outcome: odds.outcomeId,
              pick: odds.name,
              frpick: odds.frName,
              status: odds.status,
              odd: odds.odds,
              key: market.key,
              oddKey:odds.key_,
              type: 'live'
          }
        } 
        let check = this.sharedBet.find(x => x.match_id == match.id);
        if(check)
        {
          this.$store.dispatch('removeSharedBets',match.id);
        }
  
        // add bet to slip
        BetslipService.addBetslip(obj);
      }
    },
    computed: {
      ...mapGetters(['sharedBet']),
    }
  }
  </script>
  <style scoped>
    .odd {
      background-color: var(--bg-blue);
      padding: 8px 0px;
      border-radius: 5px;
      color: var(--a-text);
      text-align: center;
    }
    .odd.selected {
      background-color: var(--bg-orange);
    }
    .text-green{
      color: teal;
    }
    .text-red {
      color: red;
    }
  </style>